/***************************************************************
 * @NOTE: 医療機関詳細暫定移植で追加したファイルのため正規対応時に整理予定
 ***************************************************************/
/* eslint no-undef: 0 */
import moment from 'moment';
$(function () {
  // MRSO Planのデータ取得
  $.ajax({
    crossDomain: true,
    url: $('#hospital-plans-url').val(),
    type: 'GET',
    cache: false,
    dataType: 'json'
  })
    .done(function (json) {
    // 健診プラン一覧のリンク表示切り替え
      toggleMorePlans(json.plans.length);

      if (json.plans.length > 0 && $('.js-target-mrso-wrap-link').length) {
        $('.js-target-mrso-wrap-link').show();
      }

      $.each(json.plans.slice(0, 3), function () {
      // 取得したデータでプラン毎のカードをbuild
        buildMrsoPlansForHospital(this);
      });
      $('#mrso_plans_for_hospital').show();
    })
    .fail(function () {
      $('#mrso_plans_for_hospital').hide();
    });
});

function buildMrsoPlansForHospital (json) {
  const $template = $(document.querySelector('#mrso_plan_template li').cloneNode(true));

  // plan-link
  $template.find('.js-plan-link').attr('href', '/hospitals/' + $('#hospital-id').val() + '/mrso/plans/' + json.plan_id);
  // plan_base64_image
  let imgBase64 = '';
  if (json.plan_base64_image) {
    imgBase64 = 'data:image/png;base64,' + json.plan_base64_image;
  }
  $template.find('.js-plan-image').css('background-image', 'url(' + imgBase64 + ')');
  // plan_subject
  $template.find('.js-plan-subject').text(json.plan_subject);
  // plan-tag
  $.each(json.plan_inspections, function () {
    const tagHTML = '<span>' + this.label + '</span>';
    $template.find('.js-plan-tag').prepend(tagHTML);
  });
  // plan-price
  $template.find('.js-plan-price').html(json.plan_price.toLocaleString() + '<span>円（税込）</span>');
  // month label
  $template.find('.js-plan-first-month-name').text(convertMonthLabel(json.plan_monthly_empty_status[0].month));
  $template.find('.js-plan-second-month-name').text(convertMonthLabel(json.plan_monthly_empty_status[1].month));
  $template.find('.js-plan-third-month-name').text(convertMonthLabel(json.plan_monthly_empty_status[2].month));
  // month value
  $.each(['first', 'second', 'third'], function (idx) {
    if (json.plan_monthly_empty_status[idx].status === 1) {
      $template.find('td.js-plan-' + this + '-month-status').html(document.querySelector('#mrso_plan_template_accept_mark').cloneNode(true).innerHTML);
    } else {
      $template.find('td.js-plan-' + this + '-month-status').html(document.querySelector('#mrso_plan_template_not_accept_mark').cloneNode(true).innerHTML);
    }
  });

  $('#mrso_plans_for_hospital .js-plan-list').append($template);
}

function convertMonthLabel (strYearMonth) {
  // moment(undefined)は現在時刻になるため、先に引数チェック
  if (!strYearMonth) {
    return '';
  }

  const mStrYearMonth = moment(strYearMonth);
  if (!mStrYearMonth.isValid()) {
    return '';
  }

  return mStrYearMonth.format('M月');
}

// 健診プラン一覧のリンク表示切り替え
function toggleMorePlans (count) {
  if (count > 3) {
    $('.js-more-plans').show();
  } else {
    $('.js-more-plans').hide();
  }
}
